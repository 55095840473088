<template>

    <ODialog :ref="dialogRef" v-model:show="ndsDialog" disableResize size="lg"  :title='$t("Please rate your experience with us")' :disableMovable="false" disableFade :target="targetEl">
        <div class="o365-dialog-body d-flex flex-column ">
            <div class="mt-1 mb-2 mt-2 px-3" >
                <h6>{{ $t("How likely are you to recommend Omega 365 to a friend or colleague?") }}</h6>

                <div class="btn-group d-flex justify-content-evenly" role="group" aria-label="Button group with nested dropdown">
                    <button type="button" class="btn btn-outline-secondary" @click="doRate($event,1)">1</button>
                    <button type="button" class="btn btn-outline-secondary" @click="doRate($event,2)">2</button>
                    <button type="button" class="btn btn-outline-secondary" @click="doRate($event,3)">3</button>
                    <button type="button" class="btn btn-outline-secondary" @click="doRate($event,4)">4</button>
                    <button type="button" class="btn btn-outline-secondary" @click="doRate($event,5)">5</button>
                    <button type="button" class="btn btn-outline-secondary" @click="doRate($event,6)">6</button>
                    <button type="button" class="btn btn-outline-secondary" @click="doRate($event,7)">7</button>
                    <button type="button" class="btn btn-outline-secondary" @click="doRate($event,8)">8</button>
                    <button type="button" class="btn btn-outline-secondary" @click="doRate($event,9)">9</button>
                    <button type="button" class="btn btn-outline-secondary" @click="doRate($event,10)">10</button>
                </div>
                    
                <div class="d-flex justify-content-between mt-1 w-100">
                    <span class="me-3 mt-2">{{ ("Not likely at all") }}</span>
                    <span class="ms-3 mt-2">{{ ("Very likely") }}</span>
                </div>
            </div>
        </div>
        <div class="o365-dialog-footer d-flex just-content-right ">
            <button type="button" class="btn btn-outline-primary btn-sm" :disabled="disabled" @click="ok()">{{$t("OK")}}</button>
        </div>
    </ODialog>
</template>

<script setup>
import { ref, defineProps, onMounted } from 'vue';
import { getOrCreateDataObject } from 'o365-dataobject';
import { vPopover } from 'o365-vue-utils';
import { ODialog, OTextArea } from 'o365-ui-components';
import { $t } from 'o365-utils';
import { getOrCreateProcedure } from 'o365-modules';


const ndsDialog = ref(false);
const targetEl = ref();
const score = ref(null);
const prevElement = ref(null);
const disabled = ref(true);

const procRate = getOrCreateProcedure({id : "procAddObjectsToLease", procedureName : "sstp_System_NetPromoterScoresRate"});

const props = defineProps({
    serverData: Object
});

onMounted(() => {
    ndsDialog.value = true;
});
function cancel(){
    ndsDialog.value = false;
}

async function ok(){
    await procRate.execute( {Score:score.value} );
    ndsDialog.value = false;
}

function doRate(e, pScore){
    disabled.value = false
    if(prevElement.value){
        prevElement.value.classList.remove("active")
    }
    score.value = pScore;
    e.currentTarget.classList.add("active");
    prevElement.value = e.currentTarget
}


</script>
<style scoped>
    .active{
        background-color: var(--o365-nav-active) !important;
    }
</style>